
export default {
  name: 'VideoBlock',

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isPlaying: false,
      hasAudio: false,
      mute: true,
      mp4: null,
      webm: null,
    };
  },

  computed: {
    nativeVideo() {
      return Boolean(
        this.data.video_mp4.length > 0 || this.data.video_web.length > 0
      );
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.nativeVideo && this.containsAudio(this.$refs.video)) {
        this.hasAudio = true;
      }
    }, 2000);
    this.webm = this.data.video_web;
    this.mp4 = this.data.video_mp4;
    this.checkIfMobile();
  },

  methods: {
    play() {
      if (this.$refs.video) {
        this.$refs.video.play();
        this.isPlaying = true;
        if (!this.data.autoplay) {
          this.$refs.video.controls = 'controls';
        }
      }
    },

    pause() {
      if (this.$refs.video) {
        if (!this.$refs.video.isPlaying && this.$refs.video.readyState === 4) {
          this.isPlaying = false;
          this.$refs.video.controls = '';
        }
      }
    },

    toggleMute() {
      this.mute = !this.mute;
      if (this.mute) {
        this.$refs.video.muted = true;
      } else {
        this.$refs.video.muted = false;
      }
    },

    checkIfMobile() {
      if (window && window.innerWidth < 720 && this.data.video_mp4_mobile) {
        this.webm = this.data.video_web_mobile;
        this.mp4 = this.data.video_mp4_mobile;
      }
    },

    containsAudio(video) {
      return video
        ? video.mozHasAudio ||
            Boolean(
              video.webkitAudioDecodedByteCount ||
                Boolean(video.audioTracks && video.audioTracks.length)
            )
        : false;
    },
  },
};
